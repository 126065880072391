var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c(
        "div",
        { staticClass: "top" },
        [
          _c("a-input", {
            staticStyle: { width: "110px" },
            attrs: { allowClear: "", size: "small", placeholder: "标签名称" },
            model: {
              value: _vm.params.tagName,
              callback: function($$v) {
                _vm.$set(_vm.params, "tagName", $$v)
              },
              expression: "params.tagName"
            }
          }),
          _c(
            "a-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.search }
            },
            [_vm._v("搜索")]
          ),
          _c(
            "a-button",
            { attrs: { size: "small" }, on: { click: _vm.handleAdd } },
            [_vm._v("新增")]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table-content" },
        [
          _c("TagTable", {
            attrs: { list: _vm.data, pagination: _vm.pagination },
            on: { changePage: _vm.handleChangePage, success: _vm.getList }
          })
        ],
        1
      ),
      _c("tagEditor", { ref: "tagEditorEl", on: { success: _vm.getList } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }