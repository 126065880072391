<template>
  <div>
    <a-table
        :columns="tableList"
        :pagination="pagination"
        :data-source="list"
        :rowKey="(record, index) => index"
        @change="changePage"
    >
      <div slot-scope="row" slot="itemIcon" class="itemIcon">
        <img v-if="row.tagIcon" class="icon-box" :src="row.tagIcon" alt="">
        <div v-else class="img-null">暂无图片</div>
      </div>
      <div slot-scope="row" slot="action">
        <div>
          <a-button
              size="small"
              type="primary"
              class="setup-btn mt-10"
              @click="handleEvent(row, 'editor')"
          >编辑</a-button>
        </div>
        <div class="mt-10">
          <a-popconfirm
              title="确定删除吗？"
              ok-text="Yes"
              cancel-text="No"
              @confirm="handleEvent(row, 'delete')"
          >
            <a-button
                size="small"
                type="danger"
                class="setup-btn mt-10"
            >删除</a-button>
          </a-popconfirm>
        </div>
      </div>
    </a-table>
    <TagEditor ref="tagEditorEl" @success="handleEditor"/>
  </div>
</template>
<script>

import {tableColumns} from "@/views/cmsPage/productManage/productTagList/_data";
import TagEditor from "@/views/cmsPage/productManage/productTagList/_components/TagEditor";

export default {
  props: ['pagination', 'list'],
  components: {TagEditor},
  data() {
    return {
      tableList: tableColumns
    }
  },
  methods: {
    /** 操作 */
    handleEvent(row, type) {
      if (type === 'delete') {
        this.handleDelete(row)
      }
      if (type === 'editor') {
        this.$refs.tagEditorEl.showPopup(row)
      }
    },
    async handleDelete(row) {
      const res = await this.axios("/dq_admin/productTag/delById", {
        params: {
          id: row.id
        },
      });
      if (res.status !== '200') return
      this.handleEditor()
    },
    /** 修改好了 */
    handleEditor() {
      this.$emit("success");
    },
    /** 翻页 */
    changePage(page) {
      this.$emit("changePage", page);
    },
  }
}
</script>
<style lang="scss" scoped>
.icon-box {
  width: 100px;
  height: 100px;
}
.img-null {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ccc;
}
.itemIcon {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.color-red {
  color: red;
}
.mt-10 {
  margin-top: 10px;
}
</style>