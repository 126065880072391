export const tableColumns = [
    { title: "ID", align: "center", dataIndex: 'id' },
    { title: "标签名称", align: "center", dataIndex: 'tagName'},
    { title: "icon图标", align: "center", scopedSlots: { customRender: "itemIcon" } },
    { title: "标签描述", align: "center", dataIndex: 'tagDesc'},
    { title: "排序", align: "center", dataIndex: 'sort' },
    { title: "创建时间", align: "center", dataIndex: 'createTime' },
    { title: "操作", align: "center", scopedSlots: { customRender: "action" }},
]

export const shamBoxTypeTableColumns = [
    { title: "ID", align: "center", dataIndex: 'id' },
    { title: "分组名称", align: "center", dataIndex: 'shamName'},
    { title: "假币类型", align: "center", scopedSlots: { customRender: "itemShamTypeSlot" } },
    { title: "批号段", align: "center", scopedSlots: { customRender: "itemCodeBatchMatchSlot" } },
    { title: "图片", align: "center", scopedSlots: { customRender: "itemImgSlot" } },
    { title: "大币种", align: "center", dataIndex: 'coinName'},
    { title: "录入时间", align: "center", dataIndex: 'createTime' },
    { title: "录入员", align: "center", scopedSlots: { customRender: "itemAdminNameSlot" } },
    { title: "备注", align: "center", dataIndex: 'remark' },
    { title: "操作", align: "center", scopedSlots: { customRender: "action" }},
]
export const shamBoxTableColumns = [
    { title: "ID", align: "center", dataIndex: 'id' },
    { title: "评级公司", align: "center", scopedSlots: { customRender: 'boxCompanyTypeSlot'} },
    { title: "评级编号", align: "center", dataIndex: 'boxCode' },
    { title: "官网图", align: "center", scopedSlots: { customRender: "itemImgSlot" } },
    { title: "分组类型", align: "center", scopedSlots: { customRender: "itemShamTypeSlot" } },
    { title: "操作", align: "center", scopedSlots: { customRender: "action" }},
]