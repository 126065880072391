var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-modal",
        {
          directives: [{ name: "drag-modal", rawName: "v-drag-modal" }],
          attrs: {
            width: 600,
            title: _vm.tagTitle,
            "ok-text": "确认",
            "cancel-text": "取消"
          },
          on: { cancel: _vm.resetForms, ok: _vm.hideModal },
          model: {
            value: _vm.show,
            callback: function($$v) {
              _vm.show = $$v
            },
            expression: "show"
          }
        },
        [
          _c(
            "a-form-model",
            _vm._b(
              {
                ref: "ruleForm",
                attrs: { model: _vm.params, rules: _vm.rules }
              },
              "a-form-model",
              _vm.layout,
              false
            ),
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "标签名称" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "标签名称" },
                    model: {
                      value: _vm.params.tagName,
                      callback: function($$v) {
                        _vm.$set(_vm.params, "tagName", $$v)
                      },
                      expression: "params.tagName"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "排序号" } },
                [
                  _c("a-input", {
                    attrs: { type: "number", placeholder: "输入排序号" },
                    model: {
                      value: _vm.params.sort,
                      callback: function($$v) {
                        _vm.$set(_vm.params, "sort", $$v)
                      },
                      expression: "params.sort"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "示例图" } },
                [
                  _c(
                    "a-upload",
                    {
                      key: _vm.imgKey,
                      attrs: {
                        action: _vm.ip + "/file/upload",
                        "list-type": "picture-card",
                        "file-list": _vm.coverList,
                        maxCount: ""
                      },
                      on: {
                        preview: _vm.handlePreview,
                        change: _vm.handleChangeCoverList
                      }
                    },
                    [
                      _vm.coverList.length < 1
                        ? _c(
                            "div",
                            [
                              _c("a-icon", { attrs: { type: "plus" } }),
                              _c("div", { staticClass: "ant-upload-text" }, [
                                _vm._v("上传图片")
                              ])
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "标签描述" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "标签描述" },
                    model: {
                      value: _vm.params.tagDesc,
                      callback: function($$v) {
                        _vm.$set(_vm.params, "tagDesc", $$v)
                      },
                      expression: "params.tagDesc"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }