<template>
  <div>
    <a-modal
        v-drag-modal
        :width="600"
        v-model="show"
        :title="tagTitle"
        ok-text="确认"
        cancel-text="取消"
        @cancel="resetForms"
        @ok="hideModal"
    >
      <a-form-model
          ref="ruleForm"
          :model="params"
          :rules="rules"
          v-bind="layout"
      >
        <a-form-model-item label="标签名称">
          <a-input
              v-model="params.tagName"
              placeholder="标签名称"
          />
        </a-form-model-item>
        <a-form-model-item label="排序号">
          <a-input
              type="number"
              v-model="params.sort"
              placeholder="输入排序号"
          />
        </a-form-model-item>
        <a-form-item label="示例图">
          <a-upload
              :key="imgKey"
              :action="ip + '/file/upload'"
              list-type="picture-card"
              :file-list="coverList"
              maxCount
              @preview="handlePreview"
              @change="handleChangeCoverList"
          >
            <div v-if="coverList.length < 1">
              <a-icon type="plus" />
              <div class="ant-upload-text">上传图片</div>
            </div>
          </a-upload>
        </a-form-item>
        <a-form-model-item label="标签描述">
          <a-input
              v-model="params.tagDesc"
              placeholder="标签描述"
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: ["detail"],
  data() {
    return {
      show: false,
      params: {
        id: undefined,
        tagName: undefined,
        tagDesc: undefined,
        sort: undefined,
        tagIcon: undefined
      },
      previewVisible: false,
      previewImage: "",
      coverList: [],
      fileList: [],
      checkVersionsList: [],
      imgKey: "",
      layout: {
        labelCol: { span: 4 }, //标签占5份  共24份
        wrapperCol: { span: 20 },
      },
      rules: {
        tagName: [
          { required: true, message: "名称不能为空", trigger: "change" },
        ],
      },
      tagTitle: '新增',
      tagId: ''
    };
  },
  computed: {
    ...mapState(["ip"]),
  },
  mounted() {
    addEventListener("paste", (params) => {
      this.untils.pasteImg(params, 1, (a, b, s) => {
        this.coverList.push({
          uid: this.coverList.length + 1,
          name: "image",
          status: "done",
          url: b,
        });
      });
    });
  },
  methods: {
    showPopup(data) {
      this.resetForms()
      this.coverList = []
      if (data) {
        this.tagId = data.id
        this.tagTitle = '编辑'
        this.setDetail(data)
      }
      this.show = true
    },
    setDetail(data) {
      this.params = Object.assign(this.params, data)
      if (this.params.tagIcon) {
        const tempUrlList = [this.params.tagIcon]
        this.coverList = tempUrlList.map((items, i) => {
          const obj = {
            uid: i,
            name: "image",
            status: "done",
            url: items,
          };
          return obj;
        });
      }
    },
    handleChange({ fileList }) {
      fileList.map((row) => {
        try {
          if (row.response.data) {
            row.url = row.response.data;
          }
        } catch (e) {}
      });
      this.fileList = JSON.parse(JSON.stringify(fileList));
    },
    handleChangeCoverList({ fileList }) {
      fileList.map((row) => {
        try {
          if (row.response.data) {
            row.url = row.response.data;
          }
        } catch (e) {}
      });
      this.coverList = fileList;
    },
    previewImgs() {
      const picturesList = this.fileList.map((row) => {
        return row.url;
      });
      if (picturesList && picturesList.length > 0) {
        this.$previewImg({
          list: picturesList.reduce((pre, cur) => {
            const obj = { img_url: cur };
            pre = pre.concat(obj);
            return pre;
          }, []),

          baseImgField: "img_url",
          showMute: false,
        });
      }
    },
    handlePreview(file, type) {
      this.handlePreviewProxy(file);
    },
    async handlePreviewProxy(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    hideModal() {
      const abs = this.coverList.map((row) => {
        return row.url;
      });
      this.params.tagIcon = abs.join(",");
      if (!this.params.tagName) return this.$message.warn('标签名称不能为空')
      if (!this.params.tagIcon) return this.$message.warn('标签示例图不能为空')
      this.submit(this.params)
    },
    async submit(forms) {
      const sendObj = {
        ...forms
      };
      const res = await this.axios.post(
          "/dq_admin/productTag/addOrEdit",
          sendObj
      );
      if (res.status != 200) return;
      this.$message.success(res.message);
      this.resetForms()
      this.$emit("success");
    },
    resetForms() {
      this.params = {
        id: undefined,
        tagName: undefined,
        tagDesc: undefined,
        sort: undefined,
        tagIcon: undefined
      },
      this.show = false
    },
  },
};
</script>

<style lang="scss" scoped>
.preview-image {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-20%, -50%);
  z-index: 1001;
  padding: 32px 16px;
  background: #f2f2f2;
  border-radius: 8px;
  text-align: center;
  height: 80vh;
  img {
    height: 100%;
  }
  .preview-image-close {
    position: absolute;
    right: 0;
    top: 0;
    padding: 8px;
    cursor: pointer;
  }
}
</style>
